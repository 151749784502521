import React from "react"

const Chimney = ({width}) => (
  <svg width={width} fill="currentColor" viewBox="0 0 800 600">
    <path d="M162.552 356.594C181.802 356.594 195.788 346.223 198.495 329.688L175.937 324.728C174.583 333.747 169.921 338.707 162.402 338.707C152.927 338.707 147.513 330.59 147.513 316.461C147.513 303.383 153.078 295.567 162.251 295.567C169.62 295.567 174.433 300.227 175.786 309.095L198.495 304.436C195.638 287.45 182.554 277.83 162.853 277.83C138.189 277.83 123 292.711 123 317.212C123 341.112 138.791 356.594 162.552 356.594Z" ></path>
    <path d="M208.319 354.79H232.531V314.356C232.531 302.482 237.193 296.018 245.766 296.018C253.135 296.018 256.894 300.828 256.894 310.448V354.79H280.956V308.043C280.956 287.901 272.685 277.53 256.443 277.53C245.164 277.53 237.193 282.64 232.531 293.012V245.814H208.319V354.79Z" ></path>
    <path d="M295.084 354.79H319.297V279.634H295.084V354.79Z"></path>
    <path d="M334.577 279.634V354.79H358.789V311.801C358.789 301.73 362.85 296.168 370.068 296.168C376.686 296.168 379.994 300.828 379.994 310.598V354.79H404.056V311.651C404.056 301.58 408.117 296.168 415.335 296.168C422.253 296.168 425.411 300.678 425.411 310.749V354.79H449.474V305.638C449.474 288.052 440.901 277.68 426.314 277.68C415.486 277.68 407.666 282.791 402.252 293.313C398.492 282.64 391.574 277.68 380.896 277.68C369.768 277.68 362.549 282.791 357.737 293.162V279.634H334.577Z"></path>
    <path d="M463.509 354.79H487.722V314.206C487.722 302.632 492.384 296.168 500.806 296.168C508.476 296.168 512.085 301.129 512.085 311.5V354.79H536.147V308.193C536.147 288.052 527.876 277.68 511.634 277.68C500.204 277.68 491.933 283.091 486.669 294.064V279.634H463.509V354.79Z"></path>
    <path d="M619.621 315.408C619.621 291.509 606.387 277.83 582.776 277.83C559.316 277.83 544.577 293.313 544.577 317.964C544.577 342.314 559.616 356.594 584.43 356.594C600.071 356.594 612.854 349.229 618.869 336.903L601.124 328.786C597.665 335.701 592.1 339.308 584.731 339.308C574.655 339.308 567.587 332.845 567.437 322.623H619.621V315.408ZM596.311 309.095H567.437C567.737 298.423 573.001 292.11 582.325 292.11C591.348 292.11 596.311 297.822 596.311 308.494V309.095Z"></path>
    <path d="M634.406 385.153C654.408 385.153 665.837 377.638 672.454 358.097L699.374 279.634H675.161L662.98 325.329C661.927 329.087 661.326 331.492 661.175 334.047H660.724C660.423 330.891 659.972 328.486 659.07 325.329L645.836 279.634H619.668L648.392 354.339L647.34 357.496C645.234 364.41 640.873 367.266 632.902 367.266C630.797 367.266 628.992 367.116 624.48 366.515V384.252C628.391 384.702 632.15 385.153 634.406 385.153Z"></path>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M211.721 197.106C217.505 194.014 224.402 193.804 230.364 196.539L318.996 237.212V263.073H295.487V246.616L221.385 213.266L146.428 253.342L137.561 236.756L211.721 197.106Z" ></path>
  </svg>
)

export default Chimney
