import React from "react"

const Juicybeast = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M313.2 267.1c8.9 0 17.1-5.4 23-15.9 2.2 8 7.9 15.9 18.2 15.9 8.5 0 14.9-5.6 19.8-13.5 3.4 9.8 9.3 13.5 16.2 13.5 9 0 17.5-7.1 22.5-15.8 4.9 9.2 13.7 15.8 26.8 15.8 11.9 0 25.4-7.2 33.3-20.1 2.9 14.8 9.7 20.1 17.9 20.1 7.8 0 15-4.1 20.6-12.2-.5 11.3-1.6 26.4.6 37.4 3.3 15.3 21.3 8.7 19.3-1.3-6-31.3.7-88.5-2.7-94.5-2.7-4.7-15.3-3.3-16 2.7-.7 8.7-.7 19.3-2.7 30.6-1.3 8-7.3 19.3-14 19.3-12 0-2.7-45.3-6-51.9-2.7-5.3-16-2-16.6 3.3-1.1 8.5-1.7 15.9-2 22.5-7.5 21-21.5 26-29.3 26-21.3 0-19.3-40.6-2.7-40.6 6 0 8 6 4.7 15.3-2 6 14 8.7 16.6-2 4-16.6-5.3-28.6-20.6-28.6-21.9 0-33.2 21.6-32 41.4-2.7 7.1-8.3 14.5-12.6 14.5-12 0-2.7-45.3-6-51.9-2.7-5.3-16-2-16.6 3.3-1.7 12.7-2.3 23.2-2.1 31.9-2 5.2-6.9 16.7-13.2 16.7-12.6 0-2-47.3-5.3-53.2-3.3-5.3-16.6-2.7-17.3 3.3-.7 8.7-.7 19.3-2.7 30.6-1.3 8-7.3 19.3-14 19.3-12 0-2.7-45.3-6-51.9-2.7-5.3-16-2-16.6 3.3-6.5 51.4 3.5 66.7 17.5 66.7zm-59.9-7.3c-10.6.7-15.3-10.6-16-22 0-5.3-14.6-6-14.6 2.7 0 20.6 12 35.9 30 35.3 16-.7 31.3-9.3 31.3-41.3 0-12.6-2-32.6-4.7-52.6-1.3-10-4-12.6-18.6-10.6-13.3 2-30 10-43.3 22-10 9.3 2 22 8 16.6 14.6-13.3 27.3-20 35.9-22.6 3.3 18 4.7 34.6 4.7 47.3-.1 14.6-3.4 24.6-12.7 25.2zm130.5-75.9c8 0 14-6 14-14 0-7.3-6-13.3-14-13.3-7.3 0-13.3 6-13.3 13.3-.1 8.1 5.9 14 13.3 14z"></path>
    <path d="M485.1 304.1c12.6 1.3 14-13.3-2-15.3-57.2-7.3-140.4 0-166.4 8.7-10 3.3-12.6 20 7.3 14.6 35.3-9.3 113.2-13.3 161.1-8zm76.4 15.7c-1.8.2-7.1 1.2-13.3 2.3.3-4.6.4-7.9 0-9-2-5.3-16.6-2-17.3 3.3-.3 2.7-.5 5.7-.8 9.1-5.8 1.1-10.3 2-11.2 2.2-9.3 2-6.7 16 .7 14.6 2-.5 5.5-1.3 9.6-2.2-.2 3.8-.4 7.8-.5 11.7-2.4 6.7-6.7 13.8-12.4 20-.8-13.8-10.5-23.9-23.8-39.3 2.3-7.2 1.3-14.1-6.1-14.8-13-1.1-17.2 15.4-10.5 24.5-7.5 27.1-15.2 38.8-21.5 38.8-10.6 0 0-35.3-3.3-47.3-1.6-5.7-11.3-3.7-15.7 0-3.8-5.4-10.3-8.6-18.2-8.6-22 0-33.5 21.8-33.2 41.7-6.5 9.1-16.4 15.5-27.4 15.5-6 0-10.4-2.8-13.4-6.9 41.6-.8 47.9-50.4 10.7-50.4-45.3 0-45.3 73.9.7 73.9 14.3 0 25.6-6.9 32.9-15.5 4.1 9 11.7 15.5 23 15.5 9.3 0 17.6-5.2 23.1-14.6 3.2 11.3 9.5 14.6 16.8 14.6 17.6 0 26.3-14.7 36.7-47.9 6.6 8.1 12.6 13.7 12.6 22 0 12.6-10 12-14.6 8.7-4-2.7-8.7 8-4 12.6 2.7 2.7 7.3 4.7 13.3 4.7h1c15.3-.3 26.2-8.4 33.2-19.3 1.7 24.3 10.7 39.3 19.7 39.3 6.7 0 9.3-14 5.3-18-5.3-5.3-7.3-16-8-30.6-.3-9.3.7-22.5 1.6-33.9 7.2-1.5 13.6-2.8 15.7-3.4 5.2-1.3 3.9-13.9-1.4-13.3zm-209.7 20.7c13.5 0 10.2 23.4-13.2 23.4-1.5-11.3 3.2-23.4 13.2-23.4zm61.2 41.2c-16.6 0-14-41.9 3.3-41.9 6 0 8 6.7 4.7 16-1.3 3.8 4.7 6.3 10 4.8-3.7 11.5-8.4 21.1-18 21.1zm-124-36.1c6.4-4.8 11.1-14.4 11.1-25.7 0-12.6-8-30-31.9-30-16.6 0-31.3 10-38.6 27.3-4.7 11.3 9.3 19.3 13.3 10 6-14 15.3-21.3 25.3-21.3 8.7 0 16 6 16 16 0 9.3-4 16-14.6 22.6-1.3.7-2 2-2 6 0 1.3 0 4 2 4 10-1.3 22 6 22 20 0 11.3-7.3 20-18.6 20-13.3 0-19.3-12.6-22.6-17.3-4-5.3-14.6 1.3-12.6 8.7 2.7 10 15.3 24.6 35.3 24.6 24.6 0 36.6-17.3 36.6-35.9-.3-14.2-9.6-25.9-20.7-29z"></path>
    <path d="M249.5 324.6c-2.7 12 .7 43.3 3.3 49.9 2.7 6.7 18.6 8 16-1.3-2-8-6-35.3-4-48.6.7-7.3-13.9-6.7-15.3 0z"></path>
  </svg>
)

export default Juicybeast
