import React from "react"

const Pinjour = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M276.6 268.4c7.3-.1 13.9-6.3 14.4-14.7.5-7.8-4.6-13.9-12.4-13.8-7.3.1-13.9 6.3-14.4 14.1-.5 8.4 4.6 14.4 12.4 14.4zm357.6 79.7c-2.3-2.2-10.7-8.8-10.9-23.9-.2-17.3 5.7-36.9 5.7-41.9-.1-6.1-5.7-11.1-17.4-7.1-4.1 1.3-8.1 1-11.2 0 .4-1.7.8-3.2 1.1-4.4 7.5-26.9-15.4-27.7-18.5-9.3-1.7 9.7 2.7 15.8 8.9 19.2-4.4 17.4-11.9 54-22.6 54.1-2.2 0-2.8-2.8-2.9-8.9-.2-14.5 3.5-40.8 3.9-48 1-10.6-19.6-9.3-21.8-.3-5.7 36.9-16.1 57.1-22.8 57.2-8.9.1-3.2-40.2.5-53.6 3.5-12.1-15.6-12.1-19.6-3.6-5.4 10.8-14.6 16.7-22.4 20-1.7-14.2-9.9-25.8-26.2-25.6-10.5.1-18.6 5.7-24.1 13.7-7.2 8.7-16.8 22.6-27.3 37.2 3.7-28.3 5.9-38.9 6.3-43.2.5-5.6-17.4-5.4-20.1 1.3-.1.3-.3.7-.4 1.2-8.5 25.5-22 54.9-30.2 55-6.1.1-3.5-12.2.2-32.9 3.2-15.7-.9-27.3-13.1-27.2-8.8.1-16.6 8.5-21.6 18.2.7-3.6 1.4-7.5 1.5-11.3.5-9.5-18.5-5.4-19-1.5-3.8 14-20.3 52.7-31.2 52.8-7.8.1 1.3-32.9 4.5-48.6 1.6-10.6-19.6-9.8-21.2-1.4-6.4 26.9-13.2 65.5 8 65.2 14.6-.2 25.9-14.6 34.6-33.3-1.7 11-3.4 21.2-4 25.2-1.6 11.2 13.5 9.3 17.4 5.9 1-11.2 11.6-57.1 23.9-57.2 12.8-.1-18.9 58.8 14 58.5 11.6-.1 23.4-13.8 30.5-32.4-1.4 10.6-2.5 20.1-2.7 22.6-.1 2-.3 4-.6 6.1-16.7 18.9-30.7 37.7-32.8 51.2-1.6 10.1 3 17.8 13 17.7 16.2-.2 34.4-17.7 39-59 .7-5.8 1.3-11.2 1.9-16.1 6.9-10.2 14.2-20.6 21.1-29.8-1.9 19.6 5.9 39.4 24.3 39.2 20.9-.2 33.8-23.1 34.6-43.8 6.7-2.5 13.8-6.6 19.1-12.6-3.6 21.9-6.2 55.9 16.1 55.6 14.3-.2 21.8-11.5 26.6-25.1.7 14.1 6.7 24.7 18.6 24.6 17.8-.2 28-41.3 33.5-64.6 3.9.9 8 1 11.5.5-2.1 10.1-5.9 24.1-5.7 41.9.2 22.3 13.2 28.9 23.2 28.8 5.2-.1 10.1-3 6.8-6.3zm-272.4 53.3c-2.2 0-3.4-1.6-2.8-5 1.2-7.5 10.3-21.3 22.2-36.4-4.2 21-11.9 41.3-19.4 41.4zm38.7-75.5c.2-.4.5-.7.7-.9l-.7.9zm52.7 11.4c-14 .2-9.5-55.2 7.8-55.3 5.6-.1 8.6 5 9.7 12.2h-.6c-8.3.1-9.9 13.8.4 13.9-2 14.2-8.7 29.1-17.3 29.2z"></path>
    <path d="M402.7 263.6c7.3-.1 13.9-6.3 14.4-14.7.5-7.8-4.6-13.9-12.4-13.8-7.3.1-13.9 6.3-14.4 14.1-.5 8.4 4.6 14.5 12.4 14.4zm-253.4 6.7c-4.1 32.3 29.4 30.9 22 18.2-6.8-12.2 6.6-62.6 39-58.5 28.9 3.7 28.2 59.2-4.1 67.1 2.8-13.6 5.4-27 4.5-32.5-1.7-10.2-14.2-10.4-20.6-4.4-.4.4-.7 1-.7 1.6.3 16-10.1 49.3-9.9 68.5.2 18.6 10.4 24.9 20 20.6.8-.4 1.3-1.3 1.2-2.2-.4-3.1-1.2-11.2 0-22 .4-4.6 1.6-10.9 3-17.8 26-.5 44.3-14.1 48.8-39.2 10.9-59.4-93.8-72.2-103.2.6z"></path>
  </svg>
)

export default Pinjour
