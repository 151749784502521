import React from "react"

const Bankjoy = ({width}) => (
  <svg width={width || "100%"} fill="currentColor" viewBox="0 0 800 600">
    <path d="M190.6 272.8c12.6-2.4 20.8-10.7 20.8-24.9 0-17.6-13.3-28.4-34.8-28.4h-41.3c-1.8 0-3.2 1.4-3.2 3.2v102.5c0 1.8 1.4 3.2 3.2 3.2h43c21.5 0 34.6-11.5 34.6-30.2-.1-15-9.8-24.2-22.3-25.4zM157 237.7h14c9.7 0 15.3 4.9 15.3 13.2 0 8.3-5.8 13.2-15.3 13.2h-14v-26.4zm15.7 71.6H157v-27.7h15.7c8.9 0 15 5.3 15 13.4 0 9.1-5.7 14.3-15 14.3zm125.1-55.8h-19.3c-1.2 0-2.1 1-2.1 2.1v8.6c-4-7.6-12.6-12.8-23.3-12.8-21.6 0-35.4 17.2-35.4 39.6 0 22.5 12.8 39.6 35.1 39.6 10.3 0 19.6-5.1 23.6-12.6v8.5c0 1.2 1 2.1 2.1 2.1h19.3c1.2 0 2.1-1 2.1-2.1v-70.7c0-1.4-.9-2.3-2.1-2.3zm-38.2 57.2c-11 0-17.8-8.4-17.8-19.7s6.8-19.6 17.8-19.6c11.7 0 17.8 8.9 17.8 19.6 0 10.8-6.1 19.7-17.8 19.7zm103.2-59.3c-11.3 0-19.8 4.5-24.1 12.9v-8.8c0-1.2-1-2.1-2.1-2.1h-19.4c-1.2 0-2.1 1-2.1 2.1v70.7c0 1.2 1 2.1 2.1 2.1h19.4c1.2 0 2.1-1 2.1-2.1v-37c0-11.5 4.9-17.7 14.4-17.7 9.6 0 13.1 5.8 13.1 18.3v36.4c0 1.2 1 2.1 2.1 2.1h19.6c1.2 0 2.1-1 2.1-2.1v-44.3c0-20.2-9.1-30.5-27.2-30.5zm116.5 75.4L450.4 289l28.2-33.8c.5-.8.1-1.7-.9-1.7h-24.5c-.6 0-1.3.3-1.7.9L428 284.8v-63.1c0-1.2-1-2.1-2.1-2.1h-19.4c-1.2 0-2.1 1-2.1 2.1v104.6c0 1.2 1 2.1 2.1 2.1h19.4c1.2 0 2.1-1 2.1-2.1V296l23 31.6c.4.5 1.1.9 1.7.9h25.7c1 0 1.4-1.1.9-1.7zm21.9-109.1c-8.5 0-14.7 6.6-14.7 14.6s6.2 14.6 14.7 14.6c8.6 0 14.7-6.5 14.7-14.6 0-8-6.1-14.6-14.7-14.6z"></path>
    <path d="M510.2 254.3c-6.1 1.7-12.1 1.7-18.2 0-1.4-.4-2.8.6-2.8 2V334c0 4.9-2 7.2-6.1 7.2-1.3 0-2.2 0-3.3-.2-1.3-.2-2.5.9-2.5 2.1v14.3c0 1 .6 1.8 1.5 2 2.7.8 5.4 1.1 9.8 1.1 15.4 0 24.1-7.8 24.1-23.5v-80.7c.2-1.3-1.2-2.3-2.5-2zm53.3 76.4c25.6 0 41.3-16.9 41.3-39.9 0-22.8-15.8-39.7-41.3-39.7-25.7 0-41.4 16.8-41.4 39.7 0 23 15.7 39.9 41.4 39.9zm0-59.3c11.2 0 17.8 8.3 17.8 19.6 0 11.4-6.6 19.6-17.8 19.6-11.3 0-17.8-8.3-17.8-19.6 0-11.4 6.5-19.6 17.8-19.6z"></path>
    <path d="M683.7 253.5h-20c-1 0-1.7.6-2 1.5l-16.8 52.1-18.6-52.1c-.3-.9-1.1-1.4-2-1.4h-19.9c-1.5 0-2.6 1.5-1.9 3l30.6 73.7c-5.4 11.6-15.4 20.2-28.8 24.1-19.8 6-41.5.9-57.2-13.5-1.3-1.2-3.2-1.1-4.4.1l-10.5 10.6c-1.3 1.3-1.3 3.4.1 4.6 15.4 14.4 35.2 22.1 55.4 22.1 27.9 0 55.8-15.8 66.7-42.2l31.6-79.6c.2-1.5-.8-3-2.3-3z"></path>
  </svg>
)

export default Bankjoy
