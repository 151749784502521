import React from "react"

const Panchos = () => (
  <svg width="100%" fill="currentColor" viewBox="0 0 800 600">
    <path d="M253.9 193.9c16.9 0 27 9.3 25.1 26.2-2.5 21.3-22.1 44.9-58.2 59.4 0 0-.1 0-.1.1.5-7.1.8-13.6.8-19.4 0-9.3-13.7-5.6-14.4 2.5-.4 5.2-1.1 12.8-2.1 21.8-3.6.8-7.2 1.4-11 1.9-4.4.6-7.5 3.1-7.5 6.2s3.1 5.6 13.1 5.6c1.1 0 2.2-.1 3.7-.2-2.5 18.5-6.3 39.5-11.1 55.1-4.4 14.4 14.4 13.7 17.5 1.3 4.2-17.1 7.7-39.3 9.8-59.6 2.1-.6 4.3-1.4 6.5-2.3 49.3-20 68.4-48.1 71.5-74.9 2.5-23.1-14-39.4-40.3-39.4-52.5 0-98.1 49.3-101.9 81.3-.6 5.6 1.8 9.3 4.4 9.3 1.8 0 5-1.8 6.2-5.6 7.9-24.3 41.7-69.3 88-69.3zm398.6 104.4c-100.6 8.2-320.6 50.6-473.1 88.7-17.5 4.4-5.6 16.9 4.4 14.4 202.5-50.6 394.3-85.6 471.2-93.8 6.3-.6 5-10-2.5-9.3z"></path>
    <path d="M289.5 323.9c-5 0-.6-16.2.6-21.9 1-3.9-8.7-8.9-14.2-2.1-2.2-2.4-5.7-4.1-11.5-4.1-31.2 0-43.2 55-15.6 55 10.7 0 19.5-8.3 26-19.3.8 5.2 3.4 9.2 9.5 9.2 12.5 0 29.4-25 29.4-23.8l.6 6.9c.6 6.2 8.7 7.5 14.4 3.8 3.3-1.7 16.6-17.8 25.7-28.7.1 4.9.3 10.5 1.3 15 1.8 8.2 6.9 10 11.9 10 8 0 19-9.5 28.8-19.4 2.3 5.9 7.4 10 15.6 10 17.1 0 38.3-21.1 51.8-37.4-1.7 5.9-2.8 10.4-3 11.8-.6 5 6.9 12.5 13.7 8.2 3.4-1.7 17.9-16.1 28.5-27.1.4 3.4.5 7.4 1.5 10.2 1.3 4.4 4.4 7.5 9.3 7.5 8.3 0 23.1-11.4 35.1-22.2-.5 10.6 4.8 19.7 15 19.7 16.5 0 27.6-18.2 31.7-32.1 14.3-6.4 25.7-16.5 31.8-25.1 1.4 4.8 3.3 13.7 2.8 22.2-.6 15.6-13.1 14.4-16.2 13.1-6.2-1.8-15.6 15 5.6 15 10 0 22.5-5.6 25-21.9 3.1-18.8-6.9-42.5-7.5-46.8-1.3-4.4-10-4.4-11.3-1.3-4.6 11.5-13.8 22.8-28.8 30.9-1.3-8.2-7.5-9.2-12.4-8.5-.5-2.4-2.4-4.3-6.9-4.3-7.2 0-15.2 7.4-21 17-11.3 12.7-31.8 28.7-37.8 28.7-4.4 0-3.1-8.2-3.8-13.1-.6-6.2-9.3-8.2-15.6-2.5l-22.8 22.3c2-8.2 12.2-40.3 12.8-46.7.6-6.9-12.5-5-13.1-1.3-.5 3-3 12-5.8 22.2-17.3 23.6-41.2 47.8-52.9 47.8-15 0-3.1-31.8 5.6-31.8 1.8 0 3.1 1.3 3.1 4.4 0 2.2-1.3 4.7-2.6 6.2-1.3 1.3-1.4 3.3-.4 4.8 1.1 1.6 2.6 2.8 4.9 2.8 13.7 0 15.6-28.2-5-28.2-15.6 0-26.8 17.8-28.3 33.2-9.2 9.4-19.7 18-22.9 18-3.8 0-2.5-8.7-4.4-23.1-.6-5.6-10.6-7.5-16.2-2.5-5.2 4.8-14.9 17-24.3 27.3l1.3-10.4c1.8-10.6-6.2-11.3-10.6-7.5-4.1 3-21.6 29.9-28.4 29.9zm-35 12.5c-10.6 0-3.1-28.8 11.9-28.8 3.8 0 5.6 1.3 6.9 3.8-3.3 8.7-10.6 25-18.8 25zm322.4-97.5c-1.8 6.2 1.8 11.9 5.6 12.5-5 11.9-11.3 23.8-17.5 21.9-5.6-2-.5-23.2 11.9-34.4z"></path>
  </svg>
)

export default Panchos
